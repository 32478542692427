<template lang="html">
  <div class="info-windows vld-parent" >
    <div class="loader-container" v-if="isLoading">
      <div class="loading-icon">
        <div class="loader">Loading...</div>
      </div>

    </div>
      <div id="google-map-area"></div>


          <modal :show="isPassengersModalShown">
      <div class="p-6">
        <div class="flex items-center justify-between mt-4">
          <button
            aria-label="Close panel"
            class="transition duration-150 ease-in-out text-gray-type-2"
            @click="closePassengersModal"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            Passengers
          </div>
        </div>
        <div class="mt-12">
          <div class="">
           <passengers :userRoutes="selectedUserRoutes" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { io } from "socket.io-client";
import Modal from "@/components/Modal";
import Passengers from "./Passengers";
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";

const busIconUrl = `//${window.location.host}/icons/bus.png`;

const gMapLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["geometry"],
});

export default {
  components: {
    Passengers,
    Modal,
  },
  data() {
    return {
      isPassengersModalShown: false,
      isLoading: true,
      fullPage: true,
      activeTrips: [],
      mapInstance: null,
      loading: false,
      loaded: false,
      errorLoading: false,
      activeTripsTracking: {},
      activeTripsMarkers: {},
      activeTripObservations: {},
      selectedUserRoutes: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadGoogleMapsLib();
    });
    this.setupWebsocket();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    token() {
      if (!this.user) return null;
      return this.user.token.token;
    },
  },
  beforeUnmount() {
    Object.values(this.activeTripObservations).forEach((i) => {
      clearInterval(i);
    });
  },
  methods: {
    setupWebsocket() {
      const socket = io(process.env.VUE_APP_WS_BASE_URL, {
        transports: ["websocket", "polling"],
        auth: {
          token: this.token,
        },
        query: {
          token: this.token,
        },
      });
      socket.on("connect_error", (err) => {
        console.log(err);
      });
      socket.on("connect", (v) => {
        console.log(v);
      });
      this.io = socket;
      window.io = socket;
      window.io.on("driver_position", (data) => {
        if (data && data.driverLocation) {
          const tripId = data.tripId;
          const trip = this.activeTrips.find((i) => i.trip.id === tripId);
          if (trip) {
            this.updateTripInfo(trip, data.driverLocation);
          }
        }
      });
    },
    closePassengersModal() {
      this.isPassengersModalShown = false;
      this.selectedUserRoutes = [];
    },
    showPassengersModal(tripId) {
      this.selectedUserRoutes = this.activeTrips.filter(
        (i) => i.trip.id == tripId
      );
      this.isPassengersModalShown = true;
    },
    async monitorActiveTrips() {
      this.isLoading = true;
      this.loadActiveTrips()
        .then((data) => {
          this.activeTrips = data;
          data.forEach((item) => {
            if (window.io) {
              window.io.emit("trips:track", item.trip.id, () => {});
            }
          });
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async observeActiveTrip(item) {
      const vm = this;
      this.fetchTrackingInfo(item.trip.id).then((data) => {
        this.activeTripsTracking[item.trip.id] = data;
        if (!data.hits || !data.hits.length) {
          return;
        }
        const _source = data.hits[0]._source;
        if (_source) {
          vm.updateTripInfo(item, _source.location);
        }
      });
    },
    updateTripInfo(item, locationInfo) {
      const prevTrackingInfo = this.activeTripsTracking[item.trip.id];

      let location = locationInfo;
      if (typeof locationInfo === "string") {
        location = locationInfo.split(",").map((i) => parseFloat(i.trim()));
      }
      if (!this.activeTripsMarkers[item.trip.id]) {
        const startingPoint = item.route.pickup_coordinate
          .split(",")
          .map((i) => parseFloat(i.trim()));

        this.activeTripsMarkers[item.trip.id] = this.addBusMarker(
          startingPoint.length ? startingPoint[0] : 0,
          startingPoint.length > 1 ? startingPoint[1] : 0,
          location[0],
          location[1],
          `${item.route.pickup}`,
          `Destination: ${item.route.destination}`,
          () => {
            this.showPassengersModal(item.trip.id);
          }
        );
      } else {
        const newLatLng = new window.google.maps.LatLng(
          location[0],
          location[1]
        );
        this.activeTripsMarkers[item.trip.id].setPosition(newLatLng);
      }

      if (
        prevTrackingInfo &&
        prevTrackingInfo.hits &&
        prevTrackingInfo.hits.length
      ) {
        const prevLocation = prevTrackingInfo.hits[0]._source.location
          .split(",")
          .map((i) => parseFloat(i.trim()));
        this.activeTripsMarkers[item.trip.id].setIcon({
          url: busIconUrl,
          anchor: new window.google.maps.Point(0.5, 0.5),
          rotation: this.computeHeading(
            prevLocation[0],
            prevLocation[1],
            location[0],
            location[1]
          ),
        });
      }
    },
    async loadActiveTrips() {
      const res = await this.$http.get(
        `/corporates/${this.user.id}/active-trips`
      );
      return res.data;
    },
    async fetchTrackingInfo(tripId) {
      const res = await this.$http.get(`/tracking/${tripId}`);
      return res.data;
    },
    async loadGoogleMapsLib() {
      const vm = this;
      gMapLoader
        .load()
        .then(() => {
          vm.mapInstance = new window.google.maps.Map(
            document.getElementById("google-map-area"),
            {
              center: { lat: 6.5244, lng: 3.3792 },
              zoom: 12,
            }
          );
        })
        .finally(() => {
          vm.monitorActiveTrips();
        });
    },

    addBusMarker(prevLat, prevLng, lat, lng, title, description, onTap) {
      const contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        `<h1 id="firstHeading" class="firstHeading">${title}</h1>` +
        '<div id="bodyContent">' +
        `<p>${description}</p>` +
        "</div>" +
        "</div>";
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      const busMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: this.mapInstance,
        onTap: onTap,
        icon: {
          url: busIconUrl,
          anchor: new window.google.maps.Point(0.5, 0.5),
          rotation: this.computeHeading(
            prevLat,
            prevLng,
            location[0],
            location[1]
          ),
        },
      });

      infowindow.open(this.mapInstance, busMarker);
      return busMarker;
    },
    computeHeading(lat1, lng1, lat2, lng2) {
      const point1 = new window.google.maps.LatLng(lat1, lng1);
      const point2 = new window.google.maps.LatLng(lat2, lng2);
      return window.google.maps.geometry.spherical.computeHeading(
        point1,
        point2
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.info-windows {
  width: 100%;
  height: 100vh;
}
div#google-map-area {
  width: 100%;
  height: 100%;
}

.vld-parent {
  position: relative;
}

.loader-container {
  position: absolute;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.loading-icon {
  width: 80px;
  height: 80px;
  z-index: 10000;
  margin: auto;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>